import { Center } from "@chakra-ui/react";
import * as React from "react";
const { useRef, useState, useEffect, useMemo, memo } = React;

export interface ITopCardWrapProps {}

export const TopCardWrap: React.FC<ITopCardWrapProps> = (props) => {
  const { children } = props;
  return (
    <Center
      // maxW={{ base: "100%", md: "1200px" }}
      w="full"
      gap="5px"
      backgroundImage={`linear-gradient(180deg,hsla(0,0%,100%,.2),hsla(0,0%,100%,.2)),url("https://assets.website-files.com/618e3d0210c38214196d56d9/62444d3bdbd0ecb94ea219bd_BG%20-%20Hero%20Banner.png")`}
      backgroundRepeat="repeat,no-repeat"
      backgroundSize="auto,cover"
      p={{ base: "16px", md: "40px" }}
      pos="relative"
      bgColor="white"
      backgroundPosition="0 0,50% 0"
      flexDir="column"
      borderRadius="12px"
      color="#220247"
    >
      {children}
    </Center>
  );
};
export default memo(TopCardWrap);
