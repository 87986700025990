import * as React from "react";
import { StaticImage } from "gatsby-plugin-image";
import Layout from "@layout/index";
import TopCardWrap from "@/content/use-cases/TopCardWrap";
import GraphicText from "@/layout/GraphicText";

import Header from "@components/head";

import {
  Text,
  List,
  Button,
  ListItem,
  Box,
  Link as ChakraLink,
  VStack,
  Flex,
} from "@chakra-ui/react";
import BottomLayout from "@/layout/BottomLayout";
import { HOST_URL } from "@/utils/constant";

export const Head = () => {
  return (
    <Header
      ogUrl={`${HOST_URL}/use-cases/one-on-one-meetings`}
      title="One-on-One Meeting Productivity Software for Managers and Teams | Airgram"
      description="Schedule, transcribe live, document, share - Airgram helps team managers lead more structured and meaningful one-on-one meetings. Supercharge Zoom, Google Meet or MS Teams 1-on-1s."
    />
  );
};

const { useState } = React;
const IndexPage = () => {
  return (
    <Layout
      bottomLayout={
        <BottomLayout
          signGtmId="web_sign_up_cta_btn_use_case"
          description="Help managers run more productive 1-on-1s and clear roadblocks for teams"
          buttonText="Try Out free"
          buttonLink="https://app.airgram.io/signup"
        />
      }
    >
      <TopCardWrap>
        <Text w="80%" textAlign="center" fontSize="32px" as="h1">
          Steer 1-on-1 meetings to motivate your teams to deliver their best
          work
        </Text>
        <Text textAlign="center" fontSize={18}>
          Schedule, transcribe live, document, share - Airgram helps managers
          lead structured and meaningful one-on-one meetings
        </Text>

        <ChakraLink
          _hover={{
            textDecor: "none",
          }}
          lineHeight="52px"
          target="_blank"
          mt="24px"
          href="https://app.airgram.io/signup"
        >
          <Button
            data-gtm="web_sign_up_cta_btn_use_case"
            size="lg"
            colorScheme="brand"
          >
            Try Out Free
          </Button>
        </ChakraLink>
      </TopCardWrap>

      <VStack my="80px" gap={{ base: "40px", md: "80px", lg: "120px" }}>
        {/* 左边图片，右边文字的布局 */}
        <GraphicText
          title="Spark meaningful conversation without interruption"
          slot={
            <StaticImage
              loading="lazy"
              width={540}
              style={{ borderRadius: "12px" }}
              src="https://a.storyblok.com/f/167495/1148x732/71095f235a/1on1meetings1.png"
              alt="Use Case Team"
            />
          }
          placement="right"
        >
          <Flex
            flexDir="column"
            gap="12px"
            flex={1}
            bgColor="white"
            borderRadius="12px"
            color="#220247"
          >
            <Text fontSize={18}>
              Initiate efficient communication with the help of automatic live
              transcription. Engage fully in analyzing your teammate’s concerns
              and offer constructive feedback. Don’t forget to prepare the
              agenda ahead of time with pre-built templates.
            </Text>

            <List pl={0}>
              <ListItem>🔹 Agenda templates & timer</ListItem>
              <ListItem>
                🔹 Real-time transcription for Zoom, Google Meet, Microsoft
                Teams
              </ListItem>
              <ListItem>🔹 Auto-join Zoom calls</ListItem>
            </List>
          </Flex>
        </GraphicText>

        {/* 左边文字，右边图片的布局 */}
        <GraphicText
          title="Review centralized key information in just seconds"
          slot={
            <StaticImage
              loading="lazy"
              width={540}
              style={{ borderRadius: "12px" }}
              src="https://a.storyblok.com/f/167495/1202x828/1a05be5e4a/1on1meetings2.png"
              alt="Google Meet Transcript"
            />
          }
        >
          <Flex
            flexDir="column"
            gap="12px"
            flex={1}
            bgColor="white"
            borderRadius="12px"
            color="#220247"
          >
            <Text fontSize={18}>
              Quickly look back on all the centralized notes from your past
              1-on-1s via Related Notes. Listen to your report’s feedback
              separately to focus on how to bring out the best in your
              employees.
            </Text>

            <List pl={0}>
              <ListItem>🔹 Associated recurring meeting notes</ListItem>
              <ListItem>🔹 Listen to different speakers</ListItem>
              <ListItem>
                🔹 AI entity extraction - keywords about people, dates, numbers,
                places, brands
              </ListItem>
            </List>
          </Flex>
        </GraphicText>

        <GraphicText
          placement="right"
          title="Export notes to various work apps"
          slot={
            <StaticImage
              loading="lazy"
              width={540}
              style={{ borderRadius: "12px" }}
              src="https://a.storyblok.com/f/167495/1175x740/841dd1b2f0/1on1meetings3.png"
              alt="Google Meet Collaborative Notes"
            />
          }
        >
          <Flex
            flexDir="column"
            gap="12px"
            flex={1}
            bgColor="white"
            borderRadius="12px"
            color="#220247"
          >
            <Text fontSize={18}>
              Save the notes to your work apps instantly - Slack, Notion, Google
              Docs, Microsoft Word. Or download the video recording. Take the
              highlights out of every 1:1s easily for further improvement.
            </Text>
            <List pl={0}>
              <ListItem>
                🔹 Notion, Slack, Google Docs, MS Word integrations
              </ListItem>
              <ListItem>🔹 Download HD video recordings</ListItem>
              <ListItem>🔹 Timestamped notes</ListItem>
            </List>
          </Flex>
        </GraphicText>
      </VStack>
    </Layout>
  );
};

export default IndexPage;
